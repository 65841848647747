.fc-col-header-cell-cushion {
  width: 100%
}

.fc .fc-toolbar.fc-header-toolbar {
  display: none;
  height: 0;
}

.fc .fc-col-header-cell-cushion {
  padding: 0
}

.hidden-time-slot {
  display: none;
}

.completed-appointment {
  text-decoration: line-through !important;
  text-decoration-color: gray !important;
}

.reservation {
  border-color: lightgray;
  background: linear-gradient(
    45deg,
    white 0%,
    white 10%,
    lightgray 10%,
    lightgray 20%,
    white 20%,
    white 30%,
    lightgray 30%,
    lightgray 40%,
    white 40%,
    white 50%,
    lightgray 50%,
    lightgray 60%,
    white 60%,
    white 70%,
    lightgray 70%,
    lightgray 80%,
    white 80%,
    white 90%,
    lightgray 90%,
    lightgray 100%
  );
}

.break {
  border-color: rgba(254, 138, 138, 0.1);
  background: linear-gradient(
    45deg,
    rgb(255, 213, 219) 0%,
    rgb(254, 138, 138) 100%
  );
}

/* .selected-time-slot {
  background-color: #59fe64 !important;
  border: 1px solid #f86057 !important;
  font-weight: bold !important;
} */

/* Custom styles for FullCalendar to prevent font inheritance */
.fullcalendar-custom-font .fc-timegrid-slot-label-cushion,
.fullcalendar-custom-font .fc-timegrid-axis-cushion,
.fullcalendar-custom-font .fc-col-header-cell-cushion,
.fullcalendar-custom-font .fc-timegrid-event-harness,
.fullcalendar-custom-font .fc-daygrid-event-harness,
.fullcalendar-custom-font .fc-timegrid-now-indicator-line,
.fullcalendar-custom-font .fc-event-title,
.fullcalendar-custom-font .fc-event-time {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
}

/* Ensure time-related text elements don't inherit Poppins */
.fullcalendar-custom-font .fc-col-header,
.fullcalendar-custom-font .fc-timegrid-body,
.fullcalendar-custom-font .fc-timegrid-axis,
.fullcalendar-custom-font .fc-timegrid-slots {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
}

/* Ensure all text in the calendar uses the system font */
.fullcalendar-custom-font {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
}

/* Style for past time slots */
.fc .fc-timegrid-col.fc-day-past {
  background-color: rgba(0, 0, 0, 0.03);
}

/* Make past time slots dimmer with a slight overlay */
.fc .fc-timegrid-slot.fc-timegrid-slot-lane.fc-timegrid-slot-minor {
  position: relative;
}

/* Using pseudo-element to avoid affecting the actual content */
.fc .fc-timegrid-col.fc-day-past .fc-timegrid-col-frame::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(240, 240, 240, 0.4);
  pointer-events: none;
  z-index: 1;
}

